import * as globals from './globals';
import { client } from './fetch';
import { EventRequest, EventResponse } from './types';

//helper
import { isClient } from "utils/helper";



export const push = async (
	data: Pick<EventRequest, 'event_label' | 'event_datum'>,
): Promise<EventResponse | void> => {

	const utmParams = globals.get(globals.keys.utmParams);
	const device = globals.get(globals.keys.device);

	const newPayload = {
		...data,
		visitor_id: globals.get(globals.keys.visitorId) || "",
		session_id: globals.get(globals.keys.sessionId) || "",
		language: navigator.language,
		useragent: navigator.userAgent,
		platform: (device && device.isMobile ? "mobile" : "web"),
		utm_source: utmParams ? utmParams.utm_source : '',
		utm_campaign: utmParams ? utmParams.utm_campaign : '',
		utm_medium: utmParams ? utmParams.utm_medium : '',
		utm_content: utmParams ? utmParams.utm_content : '',
		utm_term: utmParams ? utmParams.utm_term : '',
		urlslug: window.location.pathname
	};

	const response = await client.post<EventRequest, EventResponse>(
		{
			url: process.env.API_EVENT_URL || "",
			payload: newPayload,
		}
	);
	return response.data;
}


export const pushToGA = ({ eventLabel, ...payload }) => {
	if (isClient && window.gtag && process.env.NODE_ENV === 'production') {
		window.gtag('event', eventLabel, payload);
	} else {
		console.log(`Firing event for gtag with label: ${eventLabel}, payload: ${JSON.stringify(payload, null, 4)}`);
	}
}


export const pushToPixel = ({ eventLabel, ...payload }, isCustomEvent: boolean) => {
  const shouldFirePixelEvent = isClient && window.fbq && process.env.NODE_ENV === 'production';

  if (!shouldFirePixelEvent) {
    return console.log(`Firing event for pixel with label: ${eventLabel}, payload: ${JSON.stringify(payload)}`);
  }

  return window.fbq && window.fbq(
    // to track custom event or pre-defined event
    isCustomEvent ? 'trackCustom' : 'track',
    // actual label of the event like ViewContent, Purchase etc
    eventLabel,
    // payload for the event
    { ...payload }
  )

}

